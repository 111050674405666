import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../actions/order.action";
import { DeliveryType, OrderStatus } from "../../../constants";
import { getCurrentRole } from "../../../utils/Functions";
import TagCustom from "../../atoms/TagCustom/TagCustom";
import { statusMasking } from "../../templates/OrderList/helper";

const { Option } = Select;

const getStatusLabel = (status) => OrderStatus[status]?.label ?? OrderStatus.ORDER_CREATED.label;

const getColorStatus = (status) => {
  if (status) {
    return status.toLowerCase().replace(/_/g, "-");
  }
  return status;
};

const actionsCompleted = [
  // OrderStatus.ORDER_FINISHED.value,
  OrderStatus.ORDER_RECEIVED.value,
  OrderStatus.ORDER_PARTIAL_RECEIVED.value,
  OrderStatus.ORDER_CANCELLED.value,
];

const disableOptions = (currentRole, deliveryType) => {
  return currentRole === "ADMIN" && deliveryType === DeliveryType.BASKIT_DELIVERY.value;
};

const getActionOptions = (status, currentRole, deliveryType) => {
  switch (status) {
    case OrderStatus.ORDER_CREATED.value: {
      if (currentRole === "ADMIN") {
        return [OrderStatus.ORDER_PICKED_UP.value, OrderStatus.ORDER_CANCELLED.value];
      }

      return [OrderStatus.WAITING_FOR_PICK_UP.value, OrderStatus.ORDER_CANCELLED.value];
    }
    case OrderStatus.WAITING_FOR_PICK_UP.value: {
      if (currentRole === "ADMIN" && deliveryType === DeliveryType.BASKIT_DELIVERY.value) {
        return [];
      }

      if (currentRole === "ADMIN" && deliveryType === DeliveryType.SELLER_DELIVERY.value) {
        return [OrderStatus.ORDER_PICKED_UP.value, OrderStatus.ORDER_CANCELLED.value];
      }

      return [
        OrderStatus.ORDER_PICKED_UP.value,
        OrderStatus.ORDER_CANCELLED.value,
        OrderStatus.ORDER_PENDING.value,
        OrderStatus.ORDER_CREATED.value,
      ];
    }
    case OrderStatus.ORDER_PICKED_UP.value:
      return [
        OrderStatus.ORDER_RECEIVED.value,
        OrderStatus.ORDER_CANCELLED.value,
        OrderStatus.ORDER_PENDING.value,
      ];
    // case OrderStatus.ORDER_RECEIVED.value:
    //   return [OrderStatus.ORDER_FINISHED.value];
    case OrderStatus.ORDER_PENDING.value:
      return [OrderStatus.ORDER_CREATED.value, OrderStatus.ORDER_CANCELLED.value];
    default:
      return [];
  }
};

const UpdateStatusCell = ({
  orderId,
  initialStatus,
  className = "",
  disabled = false,
  deliveryType,
}) => {
  const dispatch = useDispatch();
  const currentRole = getCurrentRole();
  const [status, setStatus] = useState(OrderStatus.ORDER_CREATED.value);
  const { updateStatusCancel, selectedOrders } = useSelector(({ orderReducer }) => orderReducer);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  useEffect(() => {
    if (updateStatusCancel) {
      setStatus(initialStatus);
    }
  }, [updateStatusCancel]);

  const handleUpdateStatus = useCallback(
    async (value) => {
      if(!value) return;

      const newSelectedOrders = selectedOrders 
        ? selectedOrders.map((order) => ({...order, status: value}))
        : [{ orderId, status: value }];
      localStorage.setItem("order-list/order/oldStatus", initialStatus);
      localStorage.setItem("order-list/order/newStatus", value);

      dispatch(
        orderActions.setUpdateOrderStatus({
          selectedOrders: newSelectedOrders,
          newStatus: value,
          updateStatusOpen: true,
        }),
      );
      setStatus(value);
    },
    [orderId, selectedOrders],
  );

  const StatusTag = React.memo(({ value }) => (
    <TagCustom
      className="strong w-full text-center"
      text={getStatusLabel(value)}
      type={getColorStatus(value)}
    />
  ));

  return (
    <div className={className} id="update-status-cell">
      {actionsCompleted.includes(status) || disableOptions(currentRole, deliveryType) ? (
        <StatusTag value={currentRole === "ADMIN" ? statusMasking(status) : status} />
      ) : (
        <Select
          className="w-full"
          disabled={disabled}
          value={{
            value: currentRole === "ADMIN" ? statusMasking(status) : status,
            label: <StatusTag value={currentRole === "ADMIN" ? statusMasking(status) : status} />,
          }}
          onChange={handleUpdateStatus}
        >
          {Object.keys(OrderStatus).map((key) => {
            const actionOptions = getActionOptions(status, currentRole, deliveryType);
            if (actionOptions.includes(key)) {
              return (
                <Option key={key} value={OrderStatus[key].value}>
                  <StatusTag value={key} />
                </Option>
              );
            }
            return null;
          })}
        </Select>
      )}
    </div>
  );
};

export default UpdateStatusCell;
