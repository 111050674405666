"use client";

import { init, track } from "@amplitude/analytics-browser";
import { createContext, useEffect, useMemo, useState } from "react";
import { server } from "../constants";

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
const localUserId = localStorage.getItem(server.USER_ID);

export const AmplitudeContext = createContext({
  userId: null,
  trackAmplitudeEvent: () => {},
});

const AmplitudeContextProvider = ({ children }) => {
  const [userId, setUserId] = useState(localUserId);

  useEffect(() => {
    const initializeAmplitude = async () => {
      const currentUserId = localUserId;

      init(AMPLITUDE_API_KEY, {
        defaultTracking: false,
        userId: currentUserId || undefined,
      });

      setUserId(currentUserId);
    };

    initializeAmplitude();
  }, []);

  const value = useMemo(() => {
    const formatTimestamp = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, "0");
      const month = now.toLocaleString("id-ID", { month: "long" });
      const year = now.getFullYear();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      console.log("Date", `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`);
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const trackAmplitudeEvent = (eventName, eventProperties) => {
      const timestamp = formatTimestamp();
      const updatedProperties = {
        ...eventProperties,
        user_id: userId,
        timestamp,
      };

      track(eventName, updatedProperties);
    };

    return {
      userId,
      trackAmplitudeEvent,
    };
  }, [userId]);

  return <AmplitudeContext.Provider value={value}>{children}</AmplitudeContext.Provider>;
};

export default AmplitudeContextProvider;
