import React, { useEffect, useMemo, useState } from "react";
import { CloseOutlined, EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Col, Row, Table, Space, Typography } from "antd";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { OrderStatus, PaymentStatus, server } from "../../../constants";
import { filterParams, getCurrentRole, parseDayToDate } from "../../../utils/Functions";
import { columnsDefault, columnsOffline, statusMasking } from "./helper";
import dayjs from "dayjs";
import ButtonPrimaryActionTable from "../../atoms/ButtonPrimaryActionTable";
import ProductSearchFilter from "../../atoms/ProductSearchFilter/ProductSearchFilter";
import PeriodSelector from "../../molecules/PeriodSelector/PeriodSelector";
import PeriodSelectorOrder from "../../molecules/PeriodSelectorOrder/PeriodSelectorOrder";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ButtonCustom from "../../atoms/ButtonCustom/ButtonCustom";
import UpdateOrdersStatusModal from "../../molecules/UpdateOrdersStatusModal/UpdateOrdersStatusModal";
import * as orderActions from "../../../actions/order.action";
import Cookies from "js-cookie";
import "./_style.scss";
import useLoggedInUser from "../../../hooks/useLoggedInUser";

const { Text } = Typography;

const defaultColumnsOptions = {
  serialNo: true,
  date: true,
  updatedAt: true,
  merchantName: false,
  orderID: true,
  customer: true,
  total: true,
  marginValue: true,
  sales: true,
  status: true,
  transactionDetails: true,
};

let orderOptions = Object.keys(PaymentStatus).map((key) => ({
  label: PaymentStatus[key].label,
  value: PaymentStatus[key].value,
}));
orderOptions.unshift({ label: "All", value: "" });

const sortValues = {
  ascend: "ASC",
  descend: "DESC",
};

const OrderList = ({
  companyId,
  columns,
  columnLabels = {},
  columnComponents = {},
  periode: initialPeriode = { label: "today", value: parseDayToDate("today") },
  baseLimit = 10,
  lazyLoad = false,
  orderFilter = {},
  scroll = { x: 1500, y: "60vh" },
  clickable = false,
  link = {},
  sticky = {},
  onRow = undefined,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [paymentStatus, setpaymentStatus] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [periode, setPeriode] = useState(initialPeriode);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState({});
  const [isSelectMultiple, setIsSelectMultiple] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isMultipleUpdates, setIsMultipleUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState([]);
  const currentRole = getCurrentRole();
  const loggedInUser = useLoggedInUser();

  const { newStatus, selectedOrders, orderMeta, orderList } = useSelector(
    (state) => state.orderReducer,
  );

  const columnOptions = columns ?? defaultColumnsOptions;
  const columnInfo = columnsOffline.filter(({ key }) => key && (columnOptions?.[key] ?? false));

  const params = useMemo(() => {
    const { start, end } = periode?.value || {};
    let orderValue = [];
    let sortValue = [];

    if (!isEmpty(sortFilter)) {
      sortFilter.forEach(({ order, sort }) => {
        orderValue.push(order);
        sortValue.push(sort);
      });
    } else {
      orderValue.push("createdAt");
      sortValue.push("DESC");
    }

    let paramsConstruct = {
      ...orderFilter,
      $page: page,
      $limit: pageSize,
      $order: orderValue.join(","),
      $sort: sortValue.join(","),
      start,
      end,
      companyId,
      search: searchValue,
      paymentStatus: paymentStatus,
    };

    return filterParams(paramsConstruct);
  }, [orderFilter, page, pageSize, periode, searchValue, paymentStatus, sortFilter]);

  useEffect(() => {
    if (paymentStatus === "") handleCloseMultipleSelect();
    handleResetMultipleSelect();
  }, [paymentStatus, periode]);

  useEffect(() => {
    setLoading(true);
    dispatch(orderActions.orderList(params));
  }, [params]);

  useEffect(() => {
    if (orderList) {
      const { data, meta } = orderList;
      const result = data;
      setData(result);
      setMeta(meta);
      setLoading(false);
    }
  }, [orderList]);

  useEffect(() => {
    if (selectedOrders) {
      const { orderId, status } = selectedOrders[0];
      setSelectedOrder({ orderId, status });
      setIsMultipleUpdates(selectedOrders.length > 1);
    }
  }, [selectedOrders]);

  const dataSource = useMemo(() => {
    return data.map((data, index) => ({
      key: index,
      id: data?.id,
      serialNo: index + (orderMeta?.start_from ?? 1),
      date: data?.createdDate,
      updatedAt: data?.updatedAt,
      merchantName: data?.companyName,
      customer: data?.customerName === '-' ? `Customer - ${loggedInUser.companyName}` : data?.customerName, 
      orderID: data?.orderCode,
      detailsPath: link.details ? link.details(data?.id) : "",
      total: data?.grandTotal,
      marginValue: data?.marginValueTotal,
      sales: data?.salesName,
      status: data?.paymentStatus,
      transactionDetails: link.invoice
        ? link.invoice(data?.id)
        : `/merchant-list/detail/${companyId}/invoice/${data?.id}`,
      isSelectMultiple: isSelectMultiple,
    }));
  }, [data, companyId, isSelectMultiple, currentRole]);

  const isOrderIncluded = (orderId, orders, key = "id") => {
    return orders.some((order) => order?.[key] === orderId);
  };

  const handleMultipleEdit = () => {
    const newSelectedOrders = data
      .filter((order) => isOrderIncluded(order?.id, selectedRows))
      .map((order) => ({ orderId: order?.id, status: order?.status }));
    dispatch(
      orderActions.setUpdateOrderStatus({
        selectedOrders: newSelectedOrders,
        newStatus: "",
        updateStatusOpen: true,
      }),
    );
  };

  const setOrderList = (isNewData = true) => {
    const orders = data.filter((order) => !isOrderIncluded(order?.id, selectedOrders, "orderId"));
    if (isNewData) dispatch(orderActions.setOrderListStateToSuccess({ data: orders, meta }));
    else dispatch(orderActions.setOrderListStateToSuccess({ data, meta }));
    handleResetMultipleSelect();
  };

  const handleUpdateStatus = async (values) => {
    setOrderList();
    setIsLoading(true);
    const isSuccess = await dispatch(orderActions.updateStatus(selectedOrder?.orderId, values));
    if (!isSuccess) setOrderList(false);
    setIsLoading(false);
    dispatch(orderActions.setUpdateOrderStatusClear());
  };

  const handleUpdateStatusToWaiting = async (fields) => {
    const { estimatePickupDate, deliveryType } = fields;
    const values = {
      orderStatus: newStatus,
      estimatePickupDate,
      deliveryType,
    };

    handleUpdateStatus(values);
  };

  const handleUpdateStatusToCancel = async (fields) => {
    const { reasonId, reasonDescription } = fields;
    let values = { orderStatus: newStatus, reasonId };
    if (reasonDescription) values.reasonDescription = reasonDescription;

    handleUpdateStatus(values);
  };

  const handleUpdateStatusBulk = async (values) => {
    setOrderList();
    setIsLoading(true);
    const isSuccess = await dispatch(orderActions.updateStatusBulk({ data: values }));
    if (!isSuccess) setOrderList(false);
    setIsLoading(false);
    dispatch(orderActions.setUpdateOrderStatusClear());
  };

  const handleMultipleUpdatesStatusToWaiting = async (fields) => {
    const { estimatePickupDate, deliveryType } = fields;
    const values = selectedOrders.map(({ orderId }) => ({
      orderId,
      orderStatus: newStatus,
      estimatePickupDate,
      deliveryType,
    }));

    handleUpdateStatusBulk(values);
  };

  const handleMultipleUpdatesStatusToCancel = async (fields) => {
    const { reasonId, reasonDescription } = fields;

    const values = selectedOrders.map(({ orderId }) => {
      let order = { orderId, orderStatus: newStatus, reasonId };
      if (reasonDescription) order.reasonDescription = reasonDescription;
      return order;
    });

    handleUpdateStatusBulk(values);
  };

  const handleMultipleUpdatesStatus = async () => {
    const values = selectedOrders.map(({ orderId }) => ({
      orderId,
      orderStatus: newStatus,
    }));

    handleUpdateStatusBulk(values);
  };

  const handleResetMultipleSelect = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleCloseMultipleSelect = () => {
    handleResetMultipleSelect();
    setIsSelectMultiple(false);
  };

  const productSearchFilterProps = {
    placeholder: "Search by merchant name, customer name or order ID",
    handleChange: (value) => setSearchValue(value),
  };

  const paginationProps = {
    pageSize: pageSize,
    current: page,
    total: meta?.totalData,
    showSizeChanger: true,
    onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
    onChange: (page) => setPage(page),
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKey, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKey);
      setSelectedRows(selectedRows);
    },
  };

  const handleTableChange = (_, __, sorter) => {
    let newSortFilter = [];
    if (Array.isArray(sorter)) {
      newSortFilter = sorter
        .reverse()
        .map(({ columnKey, order }) => ({ order: columnKey, sort: sortValues[order] }));
    } else {
      const { order, columnKey } = sorter;
      if (order !== undefined) newSortFilter.push({ order: columnKey, sort: sortValues[order] });
    }
    setSortFilter(newSortFilter);
  };

  const tableProps = {
    onRow,
    loading,
    dataSource,
    columns: columnInfo,
    pagination: paginationProps,
    scroll,
    size: "small",
    rowSelection: isSelectMultiple
      ? {
          type: "checkbox",
          ...rowSelection,
        }
      : undefined,
    onChange: handleTableChange,
  };

  return (
    <div id="order-list-template-wrapper">
      <div id="order-list-template-filter">
        <Row gutter={12}>
          <Col span={8}>
            <ProductSearchFilter {...productSearchFilterProps} />
          </Col>
          <PeriodSelector periodeState={[periode, setPeriode]} colSpan={16} />
        </Row>
      </div>
      <div id="order-list-template-filter-order">
        <Row gutter={12}>
          <PeriodSelectorOrder
            options={orderOptions}
            periodeState={[paymentStatus, setpaymentStatus]}
            colSpan={18}
          />
        </Row>
      </div>
      <div id="order-list-template-content">
        <Table async {...tableProps} />
      </div>
      <div>
        <UpdateOrdersStatusModal
          isLoading={isLoading}
          refetch={() => {}}
          handleUpdateStatusToWaiting={
            isMultipleUpdates ? handleMultipleUpdatesStatusToWaiting : handleUpdateStatusToWaiting
          }
          handleUpdateStatusToCancel={
            isMultipleUpdates ? handleMultipleUpdatesStatusToCancel : handleUpdateStatusToCancel
          }
          handleUpdateStatus={() => {
            if (isMultipleUpdates) handleMultipleUpdatesStatus();
            else handleUpdateStatus({ orderStatus: newStatus });
          }}
        />
      </div>
    </div>
  );
};

export const DateComponent = React.memo(({ value }) => (
  <Space className="date-cell" size="small" direction="vertical">
    <Text>{dayjs(value).format("DD MMM YYYY")}</Text>
    <Text>{dayjs(value).format("HH:mm")}</Text>
  </Space>
));

export const Actions = React.memo(({ link, disabled = false, newTab = false }) => {
  const history = useHistory();

  const handleClick = () => {
    if (newTab) window.open(link);
    else history.push(link);
  };

  return (
    <div className="flex-center">
      <ButtonPrimaryActionTable
        text="viewInvoice"
        title="viewInvoice"
        icon={<FileSearchOutlined />}
        disabled={disabled}
        handleClick={handleClick}
      />
    </div>
  );
});

export default OrderList;
